<template>
  <v-app-bar app color="utama" dark>
    <v-app-bar-nav-icon @click="Tdrawer()"></v-app-bar-nav-icon>

    <v-toolbar-title>PETA PEMILU</v-toolbar-title>
  </v-app-bar>
</template>

<script>
import Sdrawer from "@/store/drawer";

export default {
  data: () => ({}),

  methods: {
    Tdrawer() {
      Sdrawer.commit("toggle", !Sdrawer.state.drawer);
    },
  },
};
</script>
