<template>
  <v-navigation-drawer v-model="drawer" app>
    <!--  -->
    <v-list nav>
      <div class="py-3 text-center">
        <v-img src="@/assets/logo/koalisi.png" class="mx-auto mb-1"></v-img>
        <span class="font-weight-black primary--text"> KOALISI IDAMAN </span>
      </div>

      <v-divider></v-divider>

      <v-list-item link to="/dashboard" color="utama">
        <v-list-item-icon>
          <v-icon>mdi-view-dashboard</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link to="/cari" color="utama">
        <v-list-item-icon>
          <v-icon>mdi-account-search</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Cek Data</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link to="/map" color="utama">
        <v-list-item-icon>
          <v-icon>mdi-map</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Peta Sebaran</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link to="/laporan" color="utama">
        <v-list-item-icon>
          <v-icon>mdi-text-box</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Laporan</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item link to="/volunteer" color="utama">
        <v-list-item-icon>
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Volunteer</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link to="/tps" color="utama">
        <v-list-item-icon>
          <v-icon>mdi-archive-marker</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>TPS</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link to="/user" color="utama">
        <v-list-item-icon>
          <v-icon>mdi-account-circle</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>User</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item link @click="logout()" color="utama">
        <v-list-item-icon>
          <v-icon>mdi-power</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <div class="pa-2">
        <v-btn block small disabled> Versi : {{ appVersion }} </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { version } from "../../package";

import Cookie from "@/helper/cookie.js";
import Sdrawer from "@/store/drawer";

export default {
  data: () => ({
    appVersion: version,
  }),

  computed: {
    drawer: {
      get() {
        return Sdrawer.state.drawer;
      },
      set(value) {
        Sdrawer.commit("toggle", value);
      },
    },
  },

  methods: {
    logout() {
      Cookie.delete();
      localStorage.clear();
      this.$router.push("/login").catch(() => {});
    },
  },
};
</script>
