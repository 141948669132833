<template>
  <v-app id="inspire">
    <CMenu />

    <CHeader />

    <v-main class="mt-5" style="background-color: #FAFAFA;">
      <!--  -->
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import CHeader from "@/layout/header";
import CMenu from "@/layout/menu";

export default {
  components: {
    CHeader,
    CMenu,
  },

  data: () => ({}),

  mounted() {
    if (this.$route.path == "/") {
      this.$router.push("/dashboard").catch(() => {});
    }
  },
};
</script>
