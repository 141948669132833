import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookie from '@/helper/cookie.js'

import main from '../layout/main.vue'
import login from '../views/login.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/login',
    name: 'login',
    component: login,
  },
  {
    path: '/',
    name: 'main',
    component: main,
    meta: {
      requiresAuth: true
    },
    children: [{
        path: '/dashboard',
        name: 'dashboard',
        component: () => import( /* webpackChunkName: "dashboard" */ '../views/dashboard.vue')
      },
      {
        path: '/map',
        name: 'map',
        component: () => import( /* webpackChunkName: "map" */ '../views/map.vue')
      },
      {
        path: '/tps',
        name: 'tps',
        meta: {
          admin: true
        },
        component: () => import( /* webpackChunkName: "tps" */ '../views/tps.vue')
      },
      {
        path: '/tps/kecamatan',
        name: 'tps_kecamatan',
        meta: {
          admin: true
        },
        component: () => import( /* webpackChunkName: "tps_kecamatan" */ '../components/tps/kelurahan.vue')
      },
      {
        path: '/tps/kelurahan',
        name: 'tps_kelurahan',
        meta: {
          admin: true
        },
        component: () => import( /* webpackChunkName: "tps_kelurahan" */ '../components/tps/data.vue')
      },
      {
        path: '/volunteer',
        name: 'volunteer',
        meta: {
          admin: true
        },
        component: () => import( /* webpackChunkName: "volunteer" */ '../views/volunteer.vue')
      },
      {
        path: '/volunteer/kecamatan',
        name: 'volunteer_kecamatan',
        meta: {
          admin: true
        },
        component: () => import( /* webpackChunkName: "volunteer_kecamatan" */ '../components/volunteer/kelurahan.vue')
      },
      {
        path: '/volunteer/kelurahan',
        name: 'volunteer_kelurahan',
        meta: {
          admin: true
        },
        component: () => import( /* webpackChunkName: "volunteer_kelurahan" */ '../components/volunteer/tps.vue')
      },
      {
        path: '/volunteer/tps',
        name: 'volunteer_tps',
        meta: {
          admin: true
        },
        component: () => import( /* webpackChunkName: "volunteer_tps" */ '../components/volunteer/data.vue')
      },
      {
        path: '/cari',
        name: 'cari',
        meta: {
          superadmin: true
        },
        component: () => import( /* webpackChunkName: "cari" */ '../views/cari.vue')
      },
      {
        path: '/user',
        name: 'user',
        meta: {
          superadmin: true
        },
        component: () => import( /* webpackChunkName: "user" */ '../views/user.vue')
      },
      {
        path: '/laporan',
        name: 'laporan',
        meta: {
          superadmin: true
        },
        component: () => import( /* webpackChunkName: "laporan" */ '../views/laporan.vue')
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  var myCookie = Cookie.get('myCookie')
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!myCookie) {
      next({
        path: '/login',
        params: {
          nextUrl: to.fullPath
        }
      })
    } else {
      const session = JSON.parse(Cookie.dec(Cookie.get('myCookie')))
      if (to.matched.some(record => record.meta.superadmin)) {
        if (session.user_level == 'SU01') {
          next()
        } else {
          next({
            name: 'dashboard'
          })
        }
      } else if (to.matched.some(record => record.meta.admin)) {
        if (session.user_level == 'ADM01' || session.user_level == 'SU01') {
          next()
        } else {
          next({
            name: 'dashboard'
          })
        }
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router